
.rcw-launcher{
  width: 90px; 
  height: 90px;
  /*display: table;
  margin: 0 auto;*/
}


.rcw-conversation-container{
  margin-bottom: 4px;
}

.rcw-client, .rcw-response {
     max-width: 300px;
}

.rows .row {
    display: inline-block;
}
 
div[dir='rtl'] .rcw-send .rcw-send-icon {
    height: 25px;
    transform: rotate(180deg);
}

div[dir='rtl'] .rcw-client {
      margin-left: unset;
      margin-right: auto;
      text-align: right;
}

 